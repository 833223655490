exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-gallery-index-tsx": () => import("./../../../src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-pages-gallery-jo-tsx": () => import("./../../../src/pages/gallery/jo.tsx" /* webpackChunkName: "component---src-pages-gallery-jo-tsx" */),
  "component---src-pages-gallery-mark-tsx": () => import("./../../../src/pages/gallery/mark.tsx" /* webpackChunkName: "component---src-pages-gallery-mark-tsx" */),
  "component---src-pages-gallery-sculptural-work-tsx": () => import("./../../../src/pages/gallery/sculptural-work.tsx" /* webpackChunkName: "component---src-pages-gallery-sculptural-work-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-2-gether-tsx": () => import("./../../../src/pages/projects/2-gether.tsx" /* webpackChunkName: "component---src-pages-projects-2-gether-tsx" */),
  "component---src-pages-projects-boston-buoys-tsx": () => import("./../../../src/pages/projects/boston-buoys.tsx" /* webpackChunkName: "component---src-pages-projects-boston-buoys-tsx" */),
  "component---src-pages-projects-community-clay-tsx": () => import("./../../../src/pages/projects/community-clay.tsx" /* webpackChunkName: "component---src-pages-projects-community-clay-tsx" */),
  "component---src-pages-projects-pandemics-in-ceramics-tsx": () => import("./../../../src/pages/projects/pandemics-in-ceramics.tsx" /* webpackChunkName: "component---src-pages-projects-pandemics-in-ceramics-tsx" */),
  "component---src-pages-projects-scott-house-cc-tsx": () => import("./../../../src/pages/projects/scott-house-cc.tsx" /* webpackChunkName: "component---src-pages-projects-scott-house-cc-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-taster-sessions-tsx": () => import("./../../../src/pages/taster-sessions.tsx" /* webpackChunkName: "component---src-pages-taster-sessions-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

